import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../classes/user';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApplicationConfig} from '../../../../classes/application-config';
import {Poc} from '../../classes/poc';

@Component({
    selector: 'eaglei-edit-poc-modal',
    templateUrl: './edit-poc-modal.component.html',
    styleUrls: ['./edit-poc-modal.component.scss'],
})
export class EditPocModalComponent implements OnInit {
    // Form Properties
    public infoGroup: UntypedFormGroup;
    public contactGroup: UntypedFormGroup;

    // Data Properties
    public readonly pocData: Poc;
    public user: User;

    constructor(
        private dialogRef: MatDialogRef<EditPocModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: User,
        private userService: UserService,
        private popup: MatSnackBar
    ) {
        this.user = data;
        this.pocData = data.getFederalPoc();
    }

    ngOnInit() {
        const textPattern = Validators.pattern(/[.*[\S].*/);

        const infoControls = {
            name: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            organization: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            jobTitle: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
            department: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, textPattern]}),
        };

        const contactControls = {
            email: new UntypedFormControl('', {updateOn: 'change', validators: [Validators.required, Validators.email, textPattern]}),
            phoneNumber: new UntypedFormControl('', {
                updateOn: 'change',
                validators: [Validators.required, this.phoneNumberValidator(), textPattern],
            }),
        };

        Object.keys(infoControls).forEach((key) => {
            (infoControls[key] as UntypedFormControl).markAsTouched();
        });

        Object.keys(contactControls).forEach((key) => {
            (contactControls[key] as UntypedFormControl).markAsTouched();
        });

        this.infoGroup = new UntypedFormGroup(infoControls);
        this.contactGroup = new UntypedFormGroup(contactControls);
    }

    /**
     * saves the POC section of the user and closes the modal on success
     */
    public save(): void {
        const success = () => {
            this.popup.open('Updated user information', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.dialogRef.close(this.pocData);
        };

        const failure = (error: any) => {
            const text = error.error.userMessage || 'Failed to update user information';
            this.popup.open(text, 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
        };

        this.user.setFederalPoc(this.pocData);
        this.userService.updatePocInfo(this.pocData).subscribe(success, failure);
    }

    /**
     * Converts the formatted phone number back into a simple number.
     * @param event the value if the phone input
     * @param isPoc If the phone number to be updated is the poc phone.
     */
    public transformPhone(event: any, isPoc: boolean = false): void {
        if (event) {
            this.pocData.telephone = event.toString().replace(/[()-\s]/g, '');
        }
    }

    /**
     * checks to see if a phone number is the correct length
     */
    public phoneNumberValidator(): ValidatorFn {
        return (control) => {
            if (control.value) {
                const regex = /^[0-9()-\s]{14}$/;
                return regex.test(control.value) ? null : {length: 'Phone number must be a valid 10 digit number.'};
            }
            return null;
        };
    }

    public checkNumberInput(event: KeyboardEvent) {
        ApplicationConfig.numberInputValidation(event, true, false);
    }

    /**
     * Checks to see if all the form groups are valid
     */
    public isFormValid(): boolean {
        return this.infoGroup.valid && this.contactGroup.valid;
    }
}
