import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AuthenticationService} from 'frontend/src/app/services/authentication.service';
import {ReportRequest} from 'frontend/src/shared/classes/report-request';
import {ConfirmationComponent} from 'frontend/src/shared/modals/confirmation/confirmation.component';
import moment from 'moment';
import {filter} from 'rxjs/operators';
import {ReportManagement} from '../../classes/report-management';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'eaglei-user-defined-report-management',
    templateUrl: './user-defined-report-management.component.html',
    styleUrls: ['./user-defined-report-management.component.scss'],
})
export class UserDefinedReportManagementComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['check', 'name', 'subscription', 'email', 'start', 'end', 'frequency', 'actions', 'expand'];
    public dataSource: MatTableDataSource<ReportManagement>;

    public isGroupEditing: boolean = false;

    public selectableFilters: any[];
    public selectedType: string = 'all';

    public startMax: moment.Moment = moment().add(2, 'days');
    public startMin: moment.Moment = moment();
    public endMax: moment.Moment = moment().add(7, 'days');

    public controls: UntypedFormGroup;
    private readonly textPattern = Validators.pattern(/[.*[\S].*/);

    constructor(private userService: UserService, private authService: AuthenticationService, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.authService.authenticatedUser.getValue();
    }

    public ngAfterViewInit(): void {
        this.getReportSubscription();
    }

    private getReportSubscription(): void {
        this.controls = new UntypedFormGroup({});

        this.userService.getUserDefinedReports().subscribe((res) => {
            this.initData(res.map((d) => new ReportManagement(d)));
        });
    }

    private initData(data: ReportManagement[]): void {
        const userId = this.authService.authenticatedUser.getValue().id;

        if (this.dataSource) {
            this.dataSource.data = data.filter((d) => d.ownerId === userId);
        } else {
            this.dataSource = new MatTableDataSource(data.filter((d) => d.ownerId === userId));
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
        }

        // Create list of Selectable Filters based on what's given back
        this.selectableFilters = [];
        this.dataSource.data.forEach((d) => {
            if (!this.selectableFilters.includes(d.userDefinedReportName)) {
                this.selectableFilters.push(d.userDefinedReportName);
            }
        });
        this.dataSource.filter = ' ';

        this.userService.getReportRequest().subscribe((requests: ReportRequest[]) => {
            this.dataSource.data.forEach((d) => {
                d.reportRequests = requests.filter(
                    (r) =>
                        r.userDefinedReportId === d.userDefinedReportId &&
                        r.reportRequestGroupId === d.reportRequestGroupId &&
                        moment(r.runTime).isBetween(moment(d.startDate).subtract(1, 'day'), moment(d.endDate).add(1, 'day'))
                );
            });
        });
    }

    private filterPredicate(data: ReportManagement): boolean {
        return this.selectedType === 'all' ? true : data.userDefinedReportName === this.selectedType;
    }

    public toggleExpansion(row: ReportManagement): void {
        row.expanded = !row.expanded;
    }

    public allChecked(): boolean {
        return this.dataSource?.data?.every((d) => d.isChecked);
    }

    public isIndeterminate(): boolean {
        return this.dataSource?.data?.some((d) => d.isChecked) && !this.dataSource?.data?.every((d) => d.isChecked);
    }

    public toggleAll(checked: boolean): void {
        this.dataSource?.data?.forEach((d) => (d.isChecked = checked));
    }

    public toggleSelection(row: ReportManagement, checked: boolean): void {
        row.isChecked = checked;
    }

    public toggleGroupEdit(): void {
        if (!this.isGroupEditing && !this.dataSource.data.some((d) => d.isChecked)) {
            return;
        }

        this.isGroupEditing = !this.isGroupEditing;

        if (!this.isGroupEditing) {
            if (this.controls.valid) {
                this.dataSource.data
                    .filter((d) => d.isChecked)
                    .forEach((d) => {
                        // noinspection DuplicatedCode
                        d.subscribers = d.subscribers.filter((s) => s.trim().length > 0);

                        const tmpRequest = new ReportRequest();
                        tmpRequest.reportName = d.userDefinedReportName;
                        tmpRequest.userDefinedReportId = d.userDefinedReportId;
                        tmpRequest.reportRequestGroupId = d.reportRequestGroupId;
                        tmpRequest.parameters = [...d.reportRequests[0].parameters];
                        tmpRequest.subscribers = [...d.subscribers];
                        tmpRequest.ownerId = d.ownerId;

                        d.startDate = d.startDate.startOf('day');
                        const tmpEnd = d.endDate.add(1, 'day').startOf('day');

                        const reportRequests: ReportRequest[] = [];
                        const hourIncrement = 24 / d.frequency;
                        for (const i = d.startDate.clone(); i.isBefore(tmpEnd); i.add(hourIncrement, 'hours')) {
                            const r = new ReportRequest(tmpRequest);
                            r.runTime = i.clone().utc();

                            reportRequests.push(r);
                        }

                        this.userService.updateReportRequest(reportRequests).subscribe(() => {
                            d.isEditing = false;
                            this.getReportSubscription();
                        });
                    });
            } else {
                this.isGroupEditing = !this.isGroupEditing;
            }
        } else {
            this.dataSource.data
                .filter((d) => d.isChecked)
                .forEach((d) => {
                    d.isEditing = true;
                    // noinspection DuplicatedCode
                    d.expanded = true;
                    d.prevState = new ReportManagement(d);

                    d.subscribers.forEach((s, i) => {
                        const control = new UntypedFormControl('', {
                            updateOn: 'blur',
                            validators: [this.textPattern, Validators.email],
                        });
                        this.controls.addControl(`email${i}-${d.reportRequestGroupId}`, control);
                    });
                });
        }
    }

    public groupDelete(): void {
        if (!this.dataSource.data.some((d) => d.isChecked)) {
            return;
        }

        this.dialog
            .open(ConfirmationComponent, {data: {message: 'Are you sure you want to delete the selected Reports?'}})
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                const checkedData = this.dataSource.data.filter((d) => d.isChecked);
                checkedData.forEach((d, dataIndex) => {
                    d.reportRequests.forEach((r, rIndex) => {
                        this.userService.deleteUserDefinedReport(r.id).subscribe(() => {
                            if (dataIndex >= checkedData.length - 1 && rIndex >= d.reportRequests.length - 1) {
                                this.getReportSubscription();
                            }
                        });
                    });
                });
            });
    }

    public groupCancel(): void {
        this.isGroupEditing = false;
        this.dataSource.data
            .filter((d) => d.isChecked)
            .forEach((d) => {
                d.prevState.isEditing = false;
                d.isEditing = false;
                d.revert();
            });
    }

    public updateDate(event: any, row: ReportManagement, type: 'start' | 'end'): void {
        if (type === 'start') {
            row.startDate = event;
        } else if (type === 'end') {
            row.endDate = event;
        }
    }

    public removeEmail(row: ReportManagement, email: string): void {
        this.dialog
            .open(ConfirmationComponent, {
                data: {message: 'Are you sure you would like to delete this email from this report subscription?'},
            })
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                this.userService.removeSubscribersFromReport(row.reportRequestGroupId, [email]).subscribe(() => {
                    this.controls = new UntypedFormGroup({});

                    const index = row.subscribers.findIndex((s) => s === email);
                    row.subscribers.splice(index, 1);

                    row.subscribers.forEach((s, i) => {
                        const control = new UntypedFormControl('', {
                            updateOn: 'blur',
                            validators: [this.textPattern, Validators.email],
                        });
                        control.setValue(s);
                        this.controls.addControl(`email${i}-${row.reportRequestGroupId}`, control);
                    });
                });
            });
    }

    public toggleEdit(event: any, row: ReportManagement): void {
        event.stopPropagation();

        row.isEditing = !row.isEditing;

        if (!row.isEditing) {
            if (this.controls.valid) {
                // noinspection DuplicatedCode
                row.subscribers = row.subscribers.filter((s) => s.trim().length > 0);

                const tmpRequest = new ReportRequest();
                tmpRequest.reportName = row.userDefinedReportName;
                tmpRequest.userDefinedReportId = row.userDefinedReportId;
                tmpRequest.reportRequestGroupId = row.reportRequestGroupId;
                tmpRequest.parameters = [...row.reportRequests[0].parameters];
                tmpRequest.subscribers = [...row.subscribers];
                tmpRequest.ownerId = row.ownerId;

                row.startDate = row.startDate.startOf('day');
                const tmpEnd = row.endDate.add(1, 'day').startOf('day');

                const reportRequests: ReportRequest[] = [];
                const hourIncrement = 24 / row.frequency;
                for (const i = row.startDate.clone(); i.isBefore(tmpEnd); i.add(hourIncrement, 'hours')) {
                    const r = new ReportRequest(tmpRequest);
                    r.runTime = i.clone().utc();

                    reportRequests.push(r);
                }

                this.userService.updateReportRequest(reportRequests).subscribe(() => {
                    this.getReportSubscription();
                });
            } else {
                row.isEditing = !row.isEditing;
            }
        } else {
            row.expanded = true;
            row.prevState = new ReportManagement(row);

            row.subscribers.forEach((s, i) => {
                const control = new UntypedFormControl('', {
                    updateOn: 'blur',
                    validators: [this.textPattern, Validators.email],
                });
                this.controls.addControl(`email${i}-${row.reportRequestGroupId}`, control);
            });
        }
    }

    public unsub(event: any, row: ReportManagement): void {
        event.stopPropagation();

        this.dialog
            .open(ConfirmationComponent, {data: {message: 'Are you sure you want to delete the selected Report?'}})
            .afterClosed()
            .pipe(filter((choice) => !!choice))
            .subscribe(() => {
                row.reportRequests.forEach((r, i) => {
                    this.userService.deleteUserDefinedReport(r.id).subscribe(() => {
                        if (i >= row.reportRequests.length - 1) {
                            this.getReportSubscription();
                        }
                    });
                });
            });
    }

    public cancelEdit(event: any, row: ReportManagement): void {
        event.stopPropagation();

        row.prevState.isEditing = false;
        row.revert();
        row.isEditing = false;
    }

    public addEmail(info: ReportManagement): void {
        info.subscribers.push('');

        const control = new UntypedFormControl('', {
            updateOn: 'blur',
            validators: [this.textPattern, Validators.email],
        });
        this.controls.addControl(`email${info.subscribers.length - 1}-${info.reportRequestGroupId}`, control);
    }

    public changeFilterType(event: MatSelectChange): void {
        this.selectedType = event.value;

        this.dataSource.filter = ' ';
    }

    public hasSelected(): boolean {
        return this.dataSource?.data?.some((d) => d.isChecked);
    }

    public getFormControl(info: ReportManagement, index: number): AbstractControl {
        return this.controls.get(`email${index}-${info.reportRequestGroupId}`);
    }

    public hasError(info: ReportManagement, index: number, error: string): boolean {
        return this.getFormControl(info, index)?.hasError(error);
    }

    public canDeleteSubscribers(row: ReportManagement): boolean {
        return row.subscribers.filter((s) => s.trim().length > 0).length > 1;
    }

    // noinspection JSUnusedLocalSymbols
    public trackIndex(index: number, data: any): number {
        return index;
    }
}
