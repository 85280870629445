<div class="dialog-header">
    <span>Edit Personal Info</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <form [formGroup]="infoGroup">
        <div class="container">
            <mat-form-field class="firstname highlight-focus raised-label grow" floatLabel="always">
                <mat-label>First Name</mat-label>
                <input type="text" matInput [(ngModel)]="user.firstName" formControlName="firstName" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.firstName.hasError('required')">
                        <span>First name is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.firstName.hasError('pattern')">
                        <span>First name must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="lastname highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Last Name</mat-label>
                <input type="text" matInput [(ngModel)]="user.lastName" formControlName="lastName" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.lastName.hasError('required')">
                        <span>Last name is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.lastName.hasError('pattern')">
                        <span>Last name must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="section">
            <label class="outside-form">Username</label>
            <p>{{ user.username }}</p>
        </div>

        <div class="container">
            <mat-form-field class="job-title highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Job Title</mat-label>
                <input type="text" matInput [(ngModel)]="user.title" formControlName="jobTitle" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.jobTitle.hasError('required')">
                        <span>Job title is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.jobTitle.hasError('pattern')">
                        <span>Job title must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="department highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Department</mat-label>
                <input type="text" matInput [(ngModel)]="user.department" formControlName="department" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.department.hasError('required')">
                        <span>Department is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.department.hasError('pattern')">
                        <span>Department must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="container org-form">
            <mat-form-field class="organization highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Organization</mat-label>
                <input type="text" matInput [(ngModel)]="user.organization" formControlName="organization" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.organization.hasError('required')">
                        <span>Organization is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.organization.hasError('pattern')">
                        <span>Organization must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="container">
            <mat-form-field class="city highlight-focus raised-label grow" floatLabel="always">
                <mat-label>City</mat-label>
                <input type="text" matInput [(ngModel)]="user.city" formControlName="city" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.city.hasError('required')">
                        <span>City is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.city.hasError('pattern')">
                        <span>City must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <div class="grow state-zip">
                <mat-form-field appearance="outline" class="state highlight-focus raised-label filter-select grow" floatLabel="always">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="user.state" formControlName="state">
                        <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
                    </mat-select>

                    <mat-error>
                        <ng-container *ngIf="infoGroup.controls.state.hasError('required')">
                            <span>State is required</span>
                        </ng-container>
                        <ng-container *ngIf="infoGroup.controls.firstName.hasError('pattern')">
                            <span>State must consist of more than a space.</span>
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="zipcode highlight-focus raised-label grow" floatLabel="always">
                    <mat-label>Postal Code</mat-label>
                    <input
                        type="text"
                        matInput
                        [(ngModel)]="user.postalCode"
                        (keypress)="checkNumberInput($event)"
                        formControlName="postalCode" />
                    <mat-error>
                        <ng-container *ngIf="infoGroup.controls.postalCode.hasError('format')">
                            <span>{{ infoGroup.controls.postalCode.errors['format'] }}</span>
                        </ng-container>

                        <ng-container *ngIf="infoGroup.controls.postalCode.hasError('required')">
                            <span>Postal code is required.</span>
                        </ng-container>

                        <ng-container *ngIf="infoGroup.controls.postalCode.hasError('pattern')">
                            <span>Postal code must consist of more than a space.</span>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="section-header">CONTACT INFO</div>

    <form [formGroup]="contactGroup">
        <div class="container">
            <mat-form-field class="email highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Work Email</mat-label>
                <input type="text" matInput [(ngModel)]="user.email" formControlName="email" />
                <fa-icon
                    matSuffix
                    class="clickable"
                    icon="edit"
                    [class.selected-icon]="emailChangeRequestActive"
                    (click)="toggleEmailChangeRequest()"></fa-icon>
                <mat-error>
                    <span *ngIf="contactGroup.controls.email.hasError('required')"> An email is required </span>
                    <span *ngIf="contactGroup.controls.email.hasError('email')">
                        Email is not in proper format (ex your.name&#64;example.com)
                    </span>
                    <span *ngIf="contactGroup.controls.email.hasError('unique')">
                        {{ contactGroup.controls.email.errors['unique'] }}
                    </span>

                    <ng-container *ngIf="contactGroup.controls.email.hasError('pattern')">
                        <span>Email must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="phone highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Work Phone Number</mat-label>
                <input
                    type="text"
                    matInput
                    (keypress)="checkNumberInput($event)"
                    [ngModel]="user.telephone | phoneNumber"
                    (ngModelChange)="transformPhone($event)"
                    formControlName="phoneNumber" />

                <mat-error>
                    <span *ngIf="contactGroup.controls.phoneNumber.hasError('required')"> Phone number is required </span>

                    <span *ngIf="contactGroup.controls.phoneNumber.hasError('length')">
                        {{ contactGroup.controls.phoneNumber.errors['length'] }}
                    </span>

                    <ng-container *ngIf="contactGroup.controls.phoneNumber.hasError('pattern')">
                        <span>A phone number must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="hide request-email-container" [class.show]="emailChangeRequestActive">
            <form [formGroup]="requestGroup">
                <div class="request">
                    <mat-form-field class="email highlight-focus raised-label" floatLabel="always">
                        <mat-label>New Email</mat-label>
                        <input type="text" matInput [(ngModel)]="emailChangeRequest.email" formControlName="email" />
                        <mat-error>
                            <ng-container *ngIf="requestGroup.controls['email'].errors">
                                <span *ngIf="requestGroup.controls['email'].errors['required']">An Email is required</span>
                                <span *ngIf="requestGroup.controls['email'].errors['email']"
                                    >Email not formatted correctly (ex. user&#64;domain.com)</span
                                >
                                <span *ngIf="requestGroup.controls['email'].errors['unique']">This email is used by another account.</span>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="email-change highlight-focus raised-label grow" floatLabel="always">
                        <mat-label>Reason for New Email</mat-label>
                        <textarea
                            matInput
                            [(ngModel)]="emailChangeRequest.reason"
                            formControlName="reason"
                            matTextareaAutosize
                            matAutosizeMinRows="3"
                            matAutosizeMaxRows="3"></textarea>
                        <mat-error>A reason for the change of email is required</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </div>

        <div class="container">
            <mat-form-field class="reason highlight-focus raised-label grow" floatLabel="always">
                <mat-label>Reason</mat-label>
                <textarea
                    matInput
                    [(ngModel)]="user.reason"
                    formControlName="reason"
                    matTextareaAutosize
                    matAutosizeMinRows="4"
                    matAutosizeMaxRows="4"></textarea>
                <mat-hint>Reason for account must include connection with ESF Community.</mat-hint>
                <mat-error>
                    <ng-container *ngIf="contactGroup.controls.reason.hasError('required')">
                        <span>A reason is required</span>
                    </ng-container>
                    <ng-container *ngIf="contactGroup.controls.reason.hasError('pattern')">
                        <span>The reason for access must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<div class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!isFormValid()">Save</button>
</div>
