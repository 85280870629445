
// This file is generated by the maven plugin com.axley.js-remodel.  Do not edit!!!

import moment from 'moment';

export class GenReportManagement {

    endDate: moment.Moment;
    frequency: number;
    name: string;
    ownerId: number;
    reportRequestGroupId: number;
    startDate: moment.Moment;
    subscribers: string[];
    userDefinedReportId: number;
    userDefinedReportName: string;

    constructor();
    constructor( jsonObj: any );
    constructor( genReportManagement: GenReportManagement);

    constructor( input?: GenReportManagement ) {
        if ( input && typeof input !== 'number' ) {
            if ( input.endDate && typeof input.endDate === 'string' ) {
                this.endDate = moment(input.endDate).utc();
            } else {
                this.endDate = input.endDate;
            }
            this.frequency = input.frequency; // int [number]
            this.name = input.name; // string [string]
            this.ownerId = input.ownerId; // int [number]
            this.reportRequestGroupId = input.reportRequestGroupId; // int [number]
            if ( input.startDate && typeof input.startDate === 'string' ) {
                this.startDate = moment(input.startDate).utc();
            } else {
                this.startDate = input.startDate;
            }
            this.subscribers = input.subscribers; // list [string]
            this.userDefinedReportId = input.userDefinedReportId; // int [number]
            this.userDefinedReportName = input.userDefinedReportName; // string [string]
       }
    }

    static jsonResponseToList(arr: any[]): GenReportManagement[] {
        if (arr && arr.map) {
          return arr.map(a => new GenReportManagement(a));
        } else {
          return [];
        }
    }
}

