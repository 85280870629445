import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../classes/user';
import {UserService} from '../../services/user.service';
import {BaseModal} from '../../../../classes/base-modal';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'eaglei-edit-admin-info-modal',
    templateUrl: './edit-admin-info-modal.component.html',
    styleUrls: ['./edit-admin-info-modal.component.scss'],
})
export class EditAdminInfoModalComponent extends BaseModal {
    @ViewChild('roleList', {static: true}) roleList: ElementRef<HTMLElement>;

    private user: User;

    private readonly userRoles: GenRoleDefinition[] = [];
    private readonly excludedRoles: GenRoleDefinition[] = [];
    public readonly allRoles: GenRoleDefinition[] = [];

    constructor(
        private dialogRef: MatDialogRef<EditAdminInfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private userService: UserService,
        private popup: MatSnackBar
    ) {
        super(25);
        this.user = data.user;
        this.excludedRoles = data.excludedRoles;

        this.userRoles = this.user.roles.slice();

        this.allRoles = GenRoleDefinition.values()
            .filter((role) => !this.excludedRoles.includes(role))
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    public toggleRole(event: MatCheckboxChange, role: GenRoleDefinition): void {
        const index = this.userRoles.findIndex((r) => r === role);
        if (event.checked && index === -1) {
            this.userRoles.push(role);
        } else if (!event.checked && index !== -1) {
            this.userRoles.splice(index, 1);
        }
    }

    public isChecked(role: GenRoleDefinition): boolean {
        return this.userRoles.findIndex((r) => r === role) !== -1;
    }

    public save(): void {
        this.user.roles = this.userRoles;

        const success = () => {
            this.popup.open('Roles Updated.', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
            this.dialogRef.close(this.userRoles);
        };

        const failure = (error: any) => {
            const text = error.error.userMessage || 'Failed to update role.';
            this.popup.open(text, 'Okay', {panelClass: 'dialog-failure'});
            this.dialogRef.close(this.userRoles);
        };

        this.userService.updateRoles(this.user).subscribe(success, failure);
    }

    afterInit() {
        this.roleList.nativeElement.style.maxHeight = `${this.content.nativeElement.getBoundingClientRect().height - 35}px`;
    }
}
