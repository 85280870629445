import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserManagementComponent} from './components/user-mangement/user-management.component';
import {ResolveUserGuard} from '../../guards/resolve-user.guard';
import {ProfileComponent} from './components/profile/profile.component';
import {GenRoleDefinition} from '../../../../generated/serverModels/GenRoleDefinition';
import {RoleGuard} from '../../guards/role.guard';
import {UserDefinedReportManagementComponent} from './components/user-defined-report-management/user-defined-report-management.component';

const routes: Routes = [
    {
        path: 'manage',
        component: UserManagementComponent,
        canActivate: [RoleGuard],
        data: {
            requiredRoles: [GenRoleDefinition.ROLE_USER_MANAGER, GenRoleDefinition.ROLE_USER_REPORTER],
            title: 'User Management',
            useNewDesign: true,
        },
        resolve: {
            user: ResolveUserGuard,
        },
    },
    {
        path: 'profile',
        component: ProfileComponent,
        resolve: {
            user: ResolveUserGuard,
        },
        data: {title: 'Profile', useNewDesign: true},
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        resolve: {
            user: ResolveUserGuard,
        },
        data: {title: 'Profile', useNewDesign: true},
    },
    {
        path: 'profile/:id',
        component: ProfileComponent,
        resolve: {
            user: ResolveUserGuard,
        },
        data: {title: 'Profile', useNewDesign: true},
    },
    {
        path: 'subscription',
        component: UserDefinedReportManagementComponent,
        resolve: {
            user: ResolveUserGuard,
        },
        data: {title: 'User Defined Report', useNewDesign: true},
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule {}
