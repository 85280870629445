import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {User} from '../modules/user/classes/user';
import {UserService} from '../modules/user/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class ResolveUserGuard implements Resolve<User> {
    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot): Promise<User> {
        const userId = route.params['id'] || 0; // requesting user ID 0 gets the authenticated user.
        return this.userService.getUserById(userId).toPromise();
    }
}
