<div #gridContainer class="grid wide tall">
    <div class="personal-section">
        <ng-container [ngTemplateOutlet]="personalInfo"></ng-container>
    </div>

    <div class="poc-section" *ngIf="user.needsPoc">
        <ng-container [ngTemplateOutlet]="pointOfContact"></ng-container>
    </div>

    <div class="preferences-section">
        <ng-container [ngTemplateOutlet]="preferences"></ng-container>
    </div>

    <div class="admin-section" *ngIf="isLoggedInAsAdmin">
        <ng-container [ngTemplateOutlet]="adminInfo"></ng-container>
    </div>
</div>

<!--Personal Info-->
<ng-template #personalInfo>
    <mat-card class="personal-info">
        <mat-card-subtitle>
            <span>PERSONAL INFO</span>
            <fa-icon *ngIf="!onPhone()" class="clickable" icon="edit" (click)="openPersonalInfoModal()"></fa-icon>
        </mat-card-subtitle>
        <mat-card-content>
            <div class="info-section">
                <div class="name-info">
                    <p>
                        {{ user.fullName }} <span>({{ user.username }})</span>
                    </p>
                </div>
                <div class="office-info">
                    <span>{{ user.title }}</span> &#183; <span>{{ user.department }}</span> &#183;
                    <span>{{ user.organization }}</span>
                </div>
                <div class="location-info">
                    <span>{{ user.city }},{{ user.state }}, {{ user.postalCode }}</span>
                </div>
            </div>

            <div class="info-section">
                <p class="header">CONTACT INFO</p>
                <p class="email">{{ user.email }}</p>
                <p class="phone">{{ (user.telephone | phoneNumber) || 'Not Available' }}</p>
            </div>

            <div class="info-section">
                <p class="header">REASON FOR ACCOUNT</p>
                <p class="reason">{{ user.reason }}</p>
            </div>

            <ng-container *ngIf="apiKeys.length !== 0" [ngTemplateOutlet]="apiKeyManagement"></ng-container>

            <div class="info-section">
                <button mat-stroked-button (click)="openPasswordResetModal()">Reset password</button>

                <p>
                    <b class="primary-text">{{ user.daysSincePasswordReset }}</b> days since password reset
                </p>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Preferences-->
<ng-template #preferences>
    <mat-card>
        <mat-card-subtitle>
            <span>PREFERENCES</span>
            <fa-icon icon="edit" class="clickable" *ngIf="canEditPreferences && !onPhone()" (click)="openUserPreferenceModal()"></fa-icon>
        </mat-card-subtitle>
        <mat-card-content>
            <ng-container *ngIf="!!preferenceValues">
                <div class="info-section">
                    <p class="header">Aggregation Level</p>
                    <p>{{ preferenceValues.aggregation | aggregationLevel }}</p>
                </div>

                <div class="info-section">
                    <p class="header">Default States/Territories</p>
                    <p>{{ preferenceValues.states }}</p>
                </div>

                <div class="info-section">
                    <p class="header">Legend Style</p>
                    <p>{{ preferenceValues.legendStyle | legendName }}</p>
                    <eaglei-nom-legend
                        class="half-size"
                        [multiLine]="true"
                        [preferences]="userPreferences"
                        [disableHideOverrides]="true"></eaglei-nom-legend>
                </div>

                <div class="info-section shrink">
                    <p class="header">Legend Metric</p>
                    <p>{{ preferenceValues.metric | legendMetric }}</p>
                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Admin-->
<ng-template #adminInfo>
    <mat-card>
        <mat-card-subtitle>
            <span>ADMIN INFO</span>
            <fa-icon *ngIf="!onPhone()" icon="edit" (click)="openAdminInfoModal()"></fa-icon>
        </mat-card-subtitle>
        <mat-card-content>
            <div class="info-section" *ngIf="!user.approved">
                <p class="header">Account Approval</p>

                <div class="row gap-20">
                    <button mat-stroked-button [matMenuTriggerFor]="denialOptions">Deny</button>

                    <mat-menu #denialOptions="matMenu" overlapTrigger>
                        <button
                            mat-menu-item
                            *ngFor="let reason of denialReason"
                            (click)="denyUser(reason)"
                            [matTooltip]="reason"
                            matTooltipShowDelay="250"
                            matTooltipPosition="above">
                            {{ reason }}
                        </button>
                        <button mat-menu-item (click)="createDenyOption()">Create New Denial Option</button>
                    </mat-menu>

                    <button mat-flat-button color="primary" (click)="approveUser()">Approve</button>
                </div>
            </div>

            <div class="info-section">
                <p class="header">Roles</p>

                <div class="roles">
                    <p class="role" *ngFor="let role of visibleRoles">{{ role | roleDefinition }}</p>
                </div>
            </div>

            <div class="info-section">
                <div class="row gap-20">
                    <button mat-stroked-button [disabled]="!user.approved" (click)="user.isEnabled() ? disableUser() : enableUser()">
                        {{ user.isEnabled() ? 'Disable User' : 'Enable User' }}
                    </button>

                    <button mat-flat-button color="primary" (click)="impersonateUser()">Impersonate</button>
                </div>
            </div>

            <div class="info-section">
                <p class="header">Login Info</p>

                <div class="row gap-20">
                    <div>
                        <span>Joined </span>
                        <b> {{ user.joinDate | momentDate : 'M/D/YYYY' }}</b>
                    </div>
                    <div>
                        <span>Last Login </span>
                        <b> {{ user.lastLoginValue | momentDate : 'M/D/YYYY' }}</b>
                    </div>
                </div>
            </div>

            <div class="info-section">
                <p class="header">Password Actions</p>
                <div>
                    <button mat-stroked-button (click)="sendPasswordReset()">Send Password Reset</button>
                </div>
            </div>

            <div class="info-section" *ngIf="changeRequest">
                <p class="header">Change Email Request</p>
                <div>
                    <p>
                        Request to change <b>{{ user.email }}</b> to <b>{{ changeRequest.email }}</b>
                    </p>
                    <div class="change-reason">
                        <span>Reason</span>
                        <p>{{ changeRequest.reason }}</p>
                    </div>
                    <div
                        class="row gap-20"
                        matTooltip="Unable to update disabled user email"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250"
                        [matTooltipDisabled]="user.enabled">
                        <button mat-stroked-button [disabled]="!user.enabled" (click)="denyEmailRequest()">Deny</button>
                        <button mat-flat-button color="primary" [disabled]="!user.enabled" (click)="approveEmailRequest()">Approve</button>
                    </div>
                </div>
            </div>

            <div class="info-section shrink">
                <p class="header">Email Verified</p>
                <ng-container *ngIf="user.emailVerified; else notVerified">
                    <p>Account Email Verified</p>
                </ng-container>
                <ng-template #notVerified>
                    <div class="row gap-20">
                        <button mat-flat-button color="primary" class="ellipsis" (click)="resendEmailVerification()">
                            Resend Verification Email
                        </button>
                        <button mat-flat-button color="primary" class="ellipsis" (click)="forceEmailVerification()">
                            Verify From External Source
                        </button>
                    </div>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--POC-->
<ng-template #pointOfContact>
    <mat-card class="point-of-contact-info">
        <mat-card-subtitle>
            <span>POINT OF CONTACT</span>
            <fa-icon *ngIf="!onPhone()" icon="edit" (click)="openPointOfContactModal()"></fa-icon>
        </mat-card-subtitle>
        <mat-card-content>
            <div class="info-section">
                <div class="name-info">
                    <p>{{ user.poc.name }}</p>
                </div>

                <div class="office-info">
                    <span>{{ user.poc.title }}</span> &#183; <span>{{ user.poc.department }}</span> &#183;
                    <span>{{ user.poc.organization }}</span>
                </div>
            </div>

            <div class="info-section shrink">
                <p class="header">CONTACT INFO</p>
                <p class="email">{{ user.poc.email }}</p>
                <p class="phone">{{ user.poc.telephone | phoneNumber }}</p>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #apiKeyManagement>
    <div class="info-section">
        <p class="header">API-KEY MANAGEMENT</p>

        <div *ngFor="let apiKey of user.apiKeys" class="api-key">
            <span
                #keyName
                class="ellipsis"
                [class.disabled]="apiKey.expired"
                [matTooltip]="apiKey.system"
                [matTooltipDisabled]="!hasEllipsis(keyName)"
                >{{ apiKey.system }}</span
            >
            <span [class.disabled]="apiKey.expired">{{ apiKey.expirationDate | momentDate : 'MM/DD/YYYY' }}</span>
            <button mat-stroked-button *ngIf="!onPhone()" (click)="manageApiKey(apiKey)">Manage API-Key</button>
        </div>
    </div>
</ng-template>
