import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../classes/user';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';

@Component({
    selector: 'eaglei-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
    get isManager() {
        return this._isManager;
    }

    private _isManager;

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        const user = new User(this.activatedRoute.snapshot.data.user);
        this._isManager = user.hasRole(GenRoleDefinition.ROLE_USER_MANAGER);
    }
}
