import {ReportRequest} from 'frontend/src/shared/classes/report-request';
import {GenReportManagement} from '../../../../../generated/serverModels/GenReportManagement';

export class ReportManagement extends GenReportManagement {
    public expanded = true;
    public isEditing = false;
    public isChecked = false;

    public reportRequests: ReportRequest[] = [];

    public prevState: ReportManagement;

    constructor(input?: ReportManagement) {
        super(input);
    }

    public revert(): void {
        this.subscribers = this.prevState.subscribers;
        this.startDate = this.prevState.startDate;
        this.endDate = this.prevState.endDate;
        this.frequency = this.prevState.frequency;
    }
}
