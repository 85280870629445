import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {UserService} from './services/user.service';
import {ProfileComponent} from './components/profile/profile.component';
import {UserManagementComponent} from './components/user-mangement/user-management.component';
import {ReportModule} from '../report/report.module';
import {LegendNamePipe} from './pipes/legend-name.pipe';
import {DesignModuleModule} from '../../../design-module/design-module.module';
import {ReviewUserModalComponent} from './modals/review-user-modal/review-user-modal.component';
import {UserInfoModalComponent} from './modals/user-info-modal/user-info-modal.component';
import {PasswordResetModalComponent} from './modals/password-reset-modal/password-reset-modal.component';
import {EditPocModalComponent} from './modals/edit-poc-modal/edit-poc-modal.component';
import {EditAdminInfoModalComponent} from './modals/edit-admin-info-modal/edit-admin-info-modal.component';
import {EditPreferenceModalComponent} from './modals/edit-preferences-modal/edit-preference-modal.component';
import {ApiKeyManagementModalComponent} from './modals/api-key-management-modal/api-key-management-modal.component';
import {UserDefinedReportManagementComponent} from './components/user-defined-report-management/user-defined-report-management.component';
import {CreateTrainingUsersModalComponent} from './modals/create-training-users-modal/create-training-users-modal.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        UserManagementComponent,
        ProfileComponent,
        LegendNamePipe,
        ReviewUserModalComponent,
        UserInfoModalComponent,
        PasswordResetModalComponent,
        EditPocModalComponent,
        EditAdminInfoModalComponent,
        EditPreferenceModalComponent,
        ApiKeyManagementModalComponent,
        UserDefinedReportManagementComponent,
        CreateTrainingUsersModalComponent,
    ],
    imports: [SharedModule, DesignModuleModule, CommonModule, UserRoutingModule, ReportModule, FontAwesomeModule],
    exports: [EditPreferenceModalComponent],
    providers: [UserService],
})
export class UserModule {}
